<template>
  <div>
    <div class="flex-container">
      <ul class="nav nav-tabs">
        <li v-for="(tab, index) in tabs" :key="index" class="nav-item">
          <a class="nav-link" href="#" :class="{ 'active': tab.isActive, 'disabled': disabled }" @click.prevent="selectTab(tab)" :data-cy="tab.name">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <div class="mt-2">
      <slot :tab="selected"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {
    this.selected = this.tabs.find(el => el.isActive);
  },
  methods: {
    selectTab(selectedTab) {
      if(!this.disabled) {
        this.tabs.forEach((tab) => {
          if(tab.name === selectedTab.name) {
            tab.isActive = true;
            this.selected = tab;
          } else {
            tab.isActive = false;
          }
        });
      }
    },
  },
};
</script>
<style scoped>
  .flex-container {
    overflow-x: auto;
  }
  ul {
    flex-wrap: nowrap;
  }
  li {
    flex-shrink: 0;
  }
</style>

import "echarts/lib/chart/pie";

import baseChartFactory from "./base.echarts";

/**
 *
 * @param {string} title
 * @param {string[]} legend
 * @param {object[]} series Deve ser um vetor de uma posição.
 * @param {'pie'} series[].type
 * @param {'boolean'} [series[].roseType]
 * @param {object[]} series[].data fatias
 * @param {string} series[].data[].name nome da fatia igual na legenda
 * @param {number} series[].data[].value valor da fatia
 */
export default function optionFactory(title, legend, series) {
  return {
    ...baseChartFactory({
      title,
      legend,
      series,
      xAxisZoom: false,
      yAxisZoom: false,
      noAxis: true,
    }),
    tooltip: {
      trigger: 'item',
    }
  };
}

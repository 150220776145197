<template>
    <span v-html="nomeReal"></span>
</template>

<script>
import axios from 'axios';
import api from '@/api';

function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export default {
    props: {
        id: Number,
        nome: String,
    },
    data () {
        return {
            nomeReal: '... <strong>/</strong> ' + escapeHtml(this.nome),
        };
    },
    async mounted () {
        if(!this.nome) return this.nomeReal = '-';
        let path = this.$store.getters.getAreaHierarquica(this.id);
        if (path) this.nomeReal = path;
        else {
            let [ area ] = (await axios.get(api.v1.area.getPath([this.id]))).data;
            if (area) {
                this.nomeReal = area.path.map(escapeHtml).join(' <strong>/</strong> ');
                this.$store.commit('addAreaHierarquica', { idArea: this.id, nomeArea: this.nomeReal });
            }
        }
    },

}
</script>

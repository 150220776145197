<template>
    <two-cards>
        <template #header>
            <h4 class="card-title">Filtros</h4>
        </template>
        <template #one>
            <form @submit.prevent="pesquisar">
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <label for="i_nome">Gerência Geral</label>
                        <vue-multi-select class="multi-100" :disabled="loading" v-model="gerenciaGeral" search historyButton :filters="multiSelectFilters[0]" :options="multiSelectOptions" :selectOptions="gerenciaGeralOptions" @input="getGerencia" data-cy="Gerência Geral" :btnLabel="() => customLabel(gerenciaGeral)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_a_pai">Gerência</label>
                        <vue-multi-select class="multi-100" :disabled="!gerenciaGeral.length" v-model="gerencia" search historyButton :filters="multiSelectFilters[1]" :options="multiSelectOptions" :selectOptions="gerenciaOptions" @input="getArea" data-cy="Gerência" :btnLabel="() => customLabel(gerencia)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_a_pai">Áreas</label>
                        <vue-multi-select class="multi-100" :disabled="!gerencia.length" v-model="areas" search historyButton :filters="multiSelectFilters[2]" :options="multiSelectOptions" :selectOptions="areaOptions" @input="getSubArea" data-cy="Áreas" :btnLabel="() => customLabel(areas)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_a_pai">Subáreas</label>
                        <vue-multi-select class="multi-100" :disabled="!areas.length" v-model="subAreas" search historyButton :filters="multiSelectFilters[3]" :options="multiSelectOptions" :selectOptions="subAreaOptions" @input="updateDevices" data-cy="Subáreas" :btnLabel="() => customLabel(subAreas)"/>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-3">
                        <label for="i_nome">Concentradores</label>
                        <vue-multi-select class="multi-100"  v-model="concentradores" search historyButton :filters="multiSelectFilters[4]" :options="multiSelectOptions" :selectOptions="concentradorOptions" @input="getDetectores" data-cy="Concentradores" :btnLabel="() => customLabel(concentradores)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_nome">Detectores</label>
                        <vue-multi-select class="multi-100" :disabled="loading" v-model="detectores" search historyButton :filters="multiSelectFilters[5]" :options="multiSelectOptions" :selectOptions="detectorOptions" data-cy="Detectores" :btnLabel="() => customLabel(detectores)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_nome">Classe de alarme</label>
                        <vue-multi-select class="multi-100" v-model="classes" search historyButton :filters="multiSelectFilters[6]" :options="multiSelectOptions" :selectOptions="classeOptions" data-cy="Classe de Alarmes" :btnLabel="() => customLabel(classes)"/>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="i_nome">Severidade</label>
                        <vue-multi-select class="multi-100" v-model="severidades" search historyButton :filters="multiSelectFilters[7]" :options="multiSelectOptions" :selectOptions="severidadeOptions" data-cy="Severidade" :btnLabel="() => customLabel(severidades)"/>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col form-group col-md-4">
                        <label for="data-inicial">Data inicial</label>
                        <datepicker placeholder="Selecione" id="data-inicial" input-class="bg-white" v-model="dataInicio" :format="formatter" :language="ptBR" :bootstrap-styling="true" clear-button data-cy="Data Inicial"></datepicker>
                    </div>
                    <div class="col form-group col-md-4">
                        <label for="data-final">Data final</label>
                        <datepicker placeholder="Selecione" id="data-final" input-class="bg-white" v-model="dataFim" @selected="filtroRapido(FILTROS_RAPIDOS[0])" :format="formatter" :language="ptBR" :bootstrap-styling="true" clear-button data-cy="Data Final"></datepicker>
                    </div>
                     <div class="col form-group col-md-4">
                        <label for="tag">TAG</label>
                        <input placeholder="Este campo sobrescreve todos os outros" min="1" type="number" class="form-control" id="tag" v-model="id">
                    </div>
                </div>
                <div class="d-flex justify-content-between tcs-card-footer-action">
                    <button class="btn btn-link ml-3" type="button" data-cy="Limpar" @click="limparFiltros">Limpar filtros</button>
                    <button class="btn btn-success tcs-btn" type="submit" data-cy="Pesquisar"><SearchIcon /></button>
                </div>
            </form>
        </template>
        <template #two>
                <relatorio-list :filtros="filtros" />
        </template>
    </two-cards>
</template>

<script>
import TwoCards from "../templates/TwoCards"
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import axios from 'axios'
import api from '@/api.js'

import Datepicker from 'vuejs-datepicker';
import { ptBR } from 'vuejs-datepicker/dist/locale'

import VueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'

import RelatorioList from './RelatorioAlarmes/List'

const filters = () => [{
    nameAll: 'Selecionar todas',
    nameNotAll: 'Deselecionar todas',
    func() {
        return true;
    },
}];

export default {
    components: {
        TwoCards,
        Datepicker,
        VueMultiSelect,
        RelatorioList,
    },
    data() {
        return {
            ptBR,
            gerenciaGeral: [],
            gerencia: [],
            areas: [],
            subAreas: [],
            detectores: [],
            concentradores: [],
            classes: [],
            severidades: [],

            gerenciaGeralOptions: [],
            gerenciaOptions: [],
            areaOptions: [],
            subAreaOptions: [],
            detectorOptions: [],
            concentradorOptions: [],
            classeOptions: [],
            severidadeOptions: [{ id: 0, name:'Crítico' }, { id:1, name: 'Alarme' }, {id: 2, name: 'Alerta' }],
            allAreas: [],

            dataInicio: null,
            dataFim: null,
            id: null,

            multiSelectFilters: Array(8).fill(0).map(() => filters()),
            multiSelectOptions: {
                multi: true,
            },

            filtros: {},
            errMsg: '',
            loading: true,
        }
    },
    mounted() {
        this.getGerenciaGeral();
        this.getClassesAlarme();
    },
    methods: {
        customLabel(selected) {
            return selected.length ? `${selected.length} selecionado${selected.length > 1 ? 's' : ''}` : 'Selecione';
        },
        formatter (date) {
            return dayjs(date).locale('pt-br').format('D [de] MMMM [de] YYYY');
        },
        async getGerenciaGeral() {
            this.loading = true;
            await axios.get(api.v1.area.list(1, -1)).then(res => {
                this.allAreas = res.data.rows;
                this.gerenciaGeralOptions = res.data.rows.filter(el => el.idAreaPai === null).map(el => ({ name: el.nome, id: el.id }));
                this.loading = false;
            });
            await this.updateDevices();
        },
        async getGerencia() {
            if(this.gerenciaGeral.length === 0) {
                //reseta campos
                this.gerencia = [];
                this.areas = [];
                this.subAreas = [];
                this.areaOptions = [];
                this.subAreaOptions = [];
            }
            else await this.updateDevices();
            this.gerenciaOptions = this.allAreas.filter(el => this.gerenciaGeral.some(e => e.id === el.idAreaPai)).map(el => ({ name: el.nome, id: el.id }));

        },
        async getArea() {
            if(this.gerencia.length === 0) {
                //reseta campos
                this.areas = [];
                this.subAreas = [];
                this.subAreaOptions = [];
            }
            else await this.updateDevices();
            this.areaOptions = this.allAreas.filter(el => this.gerencia.some(e => e.id === el.idAreaPai)).map(el => ({ name: el.nome, id: el.id }));
        },
        async getSubArea() {
            if(this.areas.length === 0) {
                //reseta campos
                this.subAreas = [];
            }
            else await this.updateDevices();
            this.subAreaOptions = this.allAreas.filter(el => this.areas.some(e => e.id === el.idAreaPai)).map(el => ({ name: el.nome, id: el.id }));
        },
        getConcentradores() {
            //reseta campo
            this.concentradores = [];

            this.loading = true;
            let areas = [];
            //verifica o nivel de filtros de area
            if (this.subAreas.length > 0) {
                areas = this.subAreas.map(el => el.id);
            } else if (this.areas.length > 0) {
                areas = this.areas.map(el => el.id);
            } else if (this.gerencia.length > 0) {
                areas = this.gerencia.map(el => el.id)
            } else if (this.gerenciaGeral.length > 0) {
                areas = this.gerenciaGeral.map(el => el.id);
            } else {
                areas = this.allAreas.map(el => el.id);
            }
            return axios.get(api.v1.concentrador.listByAreas(areas)).then(res => {
                this.concentradorOptions  = res.data.map(el => ({ name: el.nome, id: el.id }));
                this.loading = false;
            });
        },
        getDetectores() {
            //reseta campo
            this.detectores = [];

            this.loading = true;
            let areas = [];
            //verifica o nivel de filtros de area
            if (this.subAreas.length > 0) {
                areas = this.subAreas.map(el => el.id);
            } else if (this.areas.length > 0) {
                areas = this.areas.map(el => el.id);
            } else if (this.gerencia.length > 0) {
                areas = this.gerencia.map(el => el.id)
            } else if (this.gerenciaGeral.length > 0) {
                areas = this.gerenciaGeral.map(el => el.id);
            } else {
                areas = this.allAreas.map(el => el.id);
            }
            return axios.get(api.v1.detector.listByAreas(areas)).then(res => {
                //Filtra os detectores de acordo com os concentradores selecionados
                if(this.concentradores.length > 0) {
                    this.detectorOptions = res.data.filter(el => this.concentradores.some(con => con.id === el.idConcentrador)
                    ).map(el => ({ name: el.nome, id: el.id }));
                } else {
                    this.detectorOptions = res.data.map(el => ({ name: el.nome, id: el.id }));
                }
                this.loading = false;
            });
        },
        getClassesAlarme() {
            //reseta campo
            this.classes = [];

            this.loading = true;
            return axios.get(api.v1.classeAlarme).then(res => {
                this.classeOptions = res.data.map(el => ({ name: el.nome, id: el.id }));
                this.loading = false;
            });
        },
        async updateDevices() {
            await this.getConcentradores();
            await this.getDetectores();
        },

        limparFiltros() {
            this.gerenciaGeral = [];
            this.gerencia = [];
            this.areas = [];
            this.subAreas = [];
            this.detectores = [];
            this.concentradores = [];
            this.classes = [];
            this.severidades = [];
            this.dataInicio = null;
            this.dataFim = null;
            this.id = null;
        },

        pesquisar() {
            if (this.id) {
                this.filtros = { id: this.id };
                return;
            }

            let filtros = {};
            //Adiciona o mais especifico
            if(this.detectores.length > 0) {
                filtros = {
                    idDetector: this.detectores.map(el => el.id),
                }
            } else if(this.concentradores.length > 0) {
                filtros = {
                    idConcentrador: this.concentradores.map(el => el.id),
                }
            } else if (this.subAreas.length > 0) {
                filtros = {
                    idArea: this.subAreas.map(el => el.id),
                }
            } else if (this.areas.length > 0) {
                filtros = {
                    idArea: this.areas.map(el => el.id),
                }
            } else if (this.gerencia.length > 0) {
                filtros = {
                    idArea: this.gerencia.map(el => el.id),
                }
            } else if (this.gerenciaGeral.length > 0) {
                filtros = {
                    idArea: this.gerenciaGeral.map(el => el.id),
                }
            }

            //Adiciona classe e severidade
            if (this.classes.length > 0) {
                filtros.classeAlarme = this.classes.map(el => el.name);
            }
            if (this.severidades.length > 0) {
                filtros.severidade = this.severidades.map(el => el.name);
            }

            //Adiciona data
            if (this.dataInicio) {
                filtros.dataHoraInicial = new Date(this.dataInicio);
            }
            if (this.dataFim) {
                filtros.dataHoraFinal = new Date(this.dataFim);
            }

            this.filtros = filtros;
        }
    }
}
</script>
<style scoped>
    label {
        display: block;
    }
</style>

import "echarts/lib/chart/bar";

import baseChartFactory from "./base.echarts";

/**
 *
 * @param {string} title
 * @param {string[]} legend
 * @param {object[]} series Deve ser um vetor de uma posição ou de várias. Caso seja do tipo stack, cada série representa uma barra empilhada no gráfico.
 * @param {string} series[].name nome da barra empilhada na legenda
 * @param {'bar'} series[].type
 * @param {'stack'|undefined} [series[].stack]
 * @param {[string, number][]} series[].data array de valores da barra empilhada [nome do eixo x, valor]
 * @param {object} series[].label formatação da label
 * @param {string|function} series[].label.formatter formatador da label (ex.: "{b}: {c} ({d}%)")
 */
export default function optionFactory(title, legend, series) {
  return {
    ...baseChartFactory({
      title,
      legend,
      series,
      yAxisZoom: false
    }),
    xAxis: {
      type: "category",
    },
  };
}
